<template>
  <v-app v-if="isLoading">
    <v-main>
      <PageLoader />
    </v-main>
  </v-app>

  <v-app v-else>
    <CreateContractsCollectionAppBar />

    <v-main class="app-container">
      <v-container class="layout-container px-0 pt-3 pt-md-5 pb-10">
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { goToLinkWithTarget } from '@/common/reusable/routing'
import CreateContractsCollectionAppBar from '@/layouts/createContractsCollection/components/CreateContractsCollectionAppBar'
import PageLoader from '@/components/PageLoader'
import FeatureFlags from '@/common/reusable/featureFlagsChecker'

export default defineComponent({
  name: 'CreateContractsCollectionLayout',
  components: {
    PageLoader,
    CreateContractsCollectionAppBar,
  },
  setup() {
    const userStore = useUserStore()
    const workspaceStore = useWorkspaceStore()

    return {
      userStore,
      workspaceStore,
    }
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    isLoading() {
      return !this.loaded
    },
    contractId() {
      return this.$route.params?.contractId
    },
    workspaceId() {
      return this.$route.params?.workspaceId
    },
    isEnabledRedirectUserAfterSign() {
      return FeatureFlags.redirectUserAfterSignEnabled(this.contract)
    },
  },
  beforeMount() {
    this.userStore.resetProfile()
    this.workspaceStore.resetWorkspaceSettings()
  },
  mounted() {
    const workspaceId = this.$route?.params?.workspaceId || localStorage.getItem('workspace_id')

    this.loaded = false
    this.userStore
      .fetchProfile()
      .then((resp) => {
        this.workspaceStore.setWorkspaceSettings(
          resp?.workspaces?.find((workspace) => `${workspaceId}` === `${workspace?.id}`),
        )

        if (this.isEnabledRedirectUserAfterSign && resp.email != this.contract?.creator?.email) {
          const redirectUrl = this.contract?.url_redirect

          if (redirectUrl) {
            return goToLinkWithTarget(redirectUrl, '_self')
          }
        }
      })
      .finally(() => {
        this.loaded = true
      })
  },
})
</script>

<style lang="scss" scoped>
.app-container {
  background-color: #fbfbfb;
}

.layout-container {
  max-width: 1110px;
  width: 100%;
}
</style>
